import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import LoadingBtn from "../../components/LoadingBtn";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Loader from "../../partials/Loader";
import LanguageSelectBar from "../../partials/tours/LanguageSelectBar";

import RenderLanguageForm from "../../partials/tours/RenderLanguageForm";
import GlobalSettingComponent from "../../partials/tours/GlobalSettingComponent";

function EditTour(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();
  const user = auth?.user;
  const [stories, setStories] = useState([]);
  const [tour, setTour] = useState({
    languages: [
      {
        title: "",
        description: "",
        coverImg: "",
        medias: "",
        locale: "",
      },
    ],
    stories: [],
    owner: "",
    visibility: 0,
    themes: [],
    unlockCode: "",
    unlockType: "code",
    unlockUrl: "",
    showPolyline: true,
    isEnabled: true,
    isDraft: false,
    isPremium: false,
    isExclusive: false,
    priority: 1,
  });
  const [languages, setLanguages] = useState({});
  const [tourOwner, setOwner] = useState(user);

  // Params
  const { id } = useParams();

  const getTour = async () => {
    try {
      const res = await axiosPrivate.get(
        endpoints.TOURS_URL + `/${id}?locale=${navigator.language}`,
        {
          signal: controller.signal,
        }
      );

      setSelectedLocale(res.data.languages[0].locale);
      console.log(res.data);
      setTour(res.data);
      setOwner(res.data.owner);
      setStories(res.data.stories);
    } catch (error) {
      console.error(error);
    }
  };

  const getLanguages = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.LANGS_URL, {
        signal: controller.signal,
      });

      setLanguages(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const main = async () => {
    try {
      await Promise.all([getTour(), getLanguages()]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    main();
  }, []);

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setIsSaving(true);
    try {
      if (values.languages.length === 0) {
        toast.error("Please add at least one language", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Check if file is uploaded
        const isFileUploaded = values.languages.every((item) => {
          let isUploaded = item.coverImg !== "loader";

          // Check media
          if (item.medias.length > 0) {
            isUploaded = item.medias.every((media) => {
              return media !== "loader";
            });
          }

          return isUploaded;
        });

        if (!isFileUploaded) {
          toast.success("Please wait for file to be uploaded!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          await axiosPrivate.patch(
            endpoints.TOURS_URL + `/${formik.values.id}`,
            formik.values,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          );

          toast.success("Edit Successfully!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        console.error("No Server res");

        toast.error("No Server res", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        let error = err?.response.data.errors.msg;
        toast.error(error, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: tour.id,
      languages: tour.languages.map((item) => {
        return {
          title: item.title || "",
          address: item.address || "",
          description: item.description || "",
          coverImg: item.coverImg || "",
          medias: item.medias || [],
          locale: item.locale || "",
        };
      }),
      stories: tour.stories.map((story) => story.id),
      owner: tour.owner.id,
      visibility: tour.visibility,
      themes: tour.themes,
      unlockCode: tour.unlockCode,
      unlockType: tour.unlockType,
      unlockUrl: tour.unlockUrl,
      showPolyline: tour.showPolyline,
      isEnabled: tour.isEnabled,
      isDraft: tour.isDraft,
      isPremium: tour.isPremium,
      isExclusive: tour.isExclusive,
      priority: tour?.priority,
    },
    validationSchema: Yup.object({
      // languages: Yup.array().of(
      //   Yup.object().shape({
      //     title: Yup.string().required("This field is required"),
      //     description: Yup.string().optional(),
      //     coverImg: Yup.string().required("This field is required"),
      //     medias: Yup.array().of(Yup.string()).optional(),
      //     locale: Yup.string().required("This field is required"),
      //   })
      // ),
      // stories: Yup.array().of(Yup.string()).required("This field is required"),
      // owner: Yup.string().required("This field is required"),
      // visibility: Yup.number()
      //   .integer()
      //   .min(0)
      //   .max(2)
      //   .required("This field is required"),
      // themes: Yup.array().of(Yup.string()).optional(),
      // tags: Yup.array().of(Yup.string()).optional(),
      showPolyline: Yup.boolean().required("This field is required"),
      isEnabled: Yup.boolean().required("This field is required"),
      isDraft: Yup.boolean().required("This field is required"),
      isPremium: Yup.boolean().required("This field is required"),
      priority: Yup.number()
      .integer()
      .min(0)
      .max(100)
      .required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const setTourOwner = (user) => {
    // Display
    setOwner(user);

    // Formik
    formik.setFieldValue("owner", user.id);
  };

  if (isLoading) return <Loader />;
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
          leftBtnComponent={
            <Link
              to={props.fallback || "/tours"}
              class="text-gray-500 hover:text-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                ></path>
              </svg>
            </Link>
          }
        />

        <main>
          <ToastContainer />

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="mx-auto flex flex-col space-y-8 rounded-lg bg-gray-50 p-5">
                {/* Page content */}
                <h3 className="text-2xl leading-6 font-bold text-gray-900">
                  Edit Tour
                </h3>
                <LanguageSelectBar
                  languages={languages}
                  selectedLocale={selectedLocale}
                  setSelectedLocale={setSelectedLocale}
                  formik={formik}
                />
                <div className="space-y-6 sm:space-y-5">
                  {formik.values.languages.map((item, idx) => {
                    if (item.locale === selectedLocale) {
                      return (
                        <RenderLanguageForm
                          key={item.locale}
                          formik={formik}
                          item={item}
                          idx={idx}
                        />
                      );
                    }
                  })}
                </div>
              </div>

              <GlobalSettingComponent
                formik={formik}
                stories={stories}
                setStories={setStories}
                user={user}
                tourOwner={tourOwner}
                setTourOwner={setTourOwner}
              />

              <div className="py-5">
                <div className="flex justify-end items-center space-x-4">
                  <Link
                    to={props.fallback || "/stories"}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  >
                    Go Back
                  </Link>
                  <div>
                    {isSaving ? (
                      <LoadingBtn text="Saving" />
                    ) : Object.keys(formik.errors).length > 0 ? (
                      <button
                        type="button"
                        disabled
                        className="btn bg-emerald-400 text-white cursor-not-allowed"
                      >
                        Save Changes
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {/* Content */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditTour;
