import React, { useEffect, useState } from "react";
import Header from "../../partials/listen/Header.js";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import endpoints from "../../api/endpoints";
import { ReactComponent as Logo } from "../../images/logo/LogoWhite.svg";

const Tours = () => {
  const axiosTryPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;

  // Get Quizs
  const [banners, setBanners] = useState([]);
  const [tours, setTours] = useState([]);
  const [stories, setStories] = useState([]);
  const [latlng, setLatlng] = useState();
  const [isBannerLoading, setBannerLoading] = useState(true);
  const [isToursLoading, setToursLoading] = useState(true);
  const [isStoriesLoading, setStoriesLoading] = useState(false);

  const getBanners = async () => {
    try {
      const res = await axiosTryPrivate.get(endpoints.EXPLORE_BANNERS, {
        signal: controller.signal,
        params: {
          ...(latlng !== null && { latlng: latlng }),
        },
      });

      setBanners(res.data || []);
    } catch (error) {
      console.error("Cannot login: ", error);
    } finally {
      setBannerLoading(false);
    }
  };

  const getTours = async () => {
    try {
      setToursLoading(true);
      const res = await axiosTryPrivate.get(endpoints.EXPLORE_TOURS, {
        signal: controller.signal,
        params: {
          ...(latlng !== null && { latlng: latlng }),
        },
      });

      setTours(res.data);
    } catch (error) {
      console.error("Cannot login: ", error);
    } finally {
      setToursLoading(false);
    }
  };

  const getStories = async () => {
    try {
      setStoriesLoading(true);
      // Check if debouncedSearchString
      const res = await axiosTryPrivate.get(endpoints.EXPLORE_STORIES, {
        signal: controller.signal,
        params: {
          ...(latlng !== null && { latlng: latlng }),
        },
      });

      setStories(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setStoriesLoading(false);
    }
  };

  const getBrowserLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      // latlng
      const latlng = `${position.coords.latitude}, ${position.coords.longitude}`;
      setLatlng(latlng);
    });
  };

  useEffect(() => {
    getBrowserLocation();
  }, []);

  useEffect(() => {
    getBanners();
    getTours();
    getStories();
  }, [latlng]);

  return (
    <>
      <Header user={user} />
      <div className="py-28 flex justify-center">
        <div className="w-full px-4 space-y-4 container">
          <Swiper
            spaceBetween={15}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            navigation={true}
            className="z-0 mx-0"
          >
            {isBannerLoading &&
              Array.from(Array(10).keys()).map((idx) => (
                <SwiperSlide
                  key={`banner-loader-${idx}`}
                  className="aspect-12/5"
                >
                  <div className="animate-pulse h-full w-full object-cover group rounded-lg bg-gray-600 dark:bg-gray-800 overflow-hidden"></div>
                </SwiperSlide>
              ))}
            {!isBannerLoading &&
              banners.map((item, idx) => (
                <SwiperSlide key={`banner-${idx}`} className="aspect-12/5">
                  <Link to={item.staticLink || ""}>
                    <img
                      className="h-full w-full rounded-lg object-cover bg-center"
                      src={item.coverImg || ""}
                      alt={item.title}
                    />
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>

          {/* Section: Tours */}
          <div>
            {/* Page header */}
            <div className="mb-2 sm:flex sm:justify-between sm:items-center">
              {/* Left: Title */}
              <h1 className="text-2xl md:text-3xl text-black font-bold">
                Explore Tours
              </h1>
            </div>

            <Swiper
              slidesPerView="auto"
              spaceBetween={15}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loopedSlides={12}
              modules={[Navigation, Autoplay]}
              navigation={true}
              className="max-w-fit z-0 mx-0"
            >
              {isToursLoading &&
                Array.from(Array(10).keys()).map((idx) => (
                  <SwiperSlide key={`tour-loader-${idx}`} className="max-w-fit">
                    <div className="animate-pulse p-4 h-48 group block aspect-square rounded-lg bg-gray-600 dark:bg-gray-800 overflow-hidden"></div>
                    <div className="mt-1 h-6 w-36 rounded-full animate-pulse bg-gray-400 dark:bg-gray-600"></div>
                    <div className="mt-1 h-4 w-24 rounded-full animate-pulse bg-gray-400 dark:bg-gray-600"></div>
                  </SwiperSlide>
                ))}
              {!isToursLoading &&
                tours.map((item, idx) => (
                  <SwiperSlide key={`tour-${idx}`} className="max-w-fit">
                    <Link to={`/listen/tour/${item.id}`}>
                      {item.coverImg ? (
                        <img
                          src={item.coverImg}
                          alt={item.title}
                          className="h-48 aspect-square rounded-lg object-cover"
                        />
                      ) : (
                        <div className="p-4 h-48 flex items-center justify-center group aspect-square rounded-lg bg-gray-600 dark:bg-gray-800 overflow-hidden">
                          <Logo className="h-12 w-12 text-white" />
                        </div>
                      )}{" "}
                      <div className="mt-1 w-48 truncate h5 text-black">
                        {item.title}
                      </div>
                      <div className="mt-1 w-48 truncate text-sm font-bold text-gray-700">
                        @{item.owner.username}
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          {/* All stories */}
          <div>
            {/* Page header */}
            <div className="mb-2 sm:flex sm:justify-between sm:items-center">
              {/* Left: Title */}
              <h1 className="text-2xl md:text-3xl text-black font-bold">
                Explore Stories
              </h1>
            </div>
            <Swiper
              slidesPerView="auto"
              spaceBetween={15}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loopedSlides={12}
              modules={[Navigation, Autoplay]}
              navigation={true}
              className="max-w-fit z-0 mx-0"
            >
              {isStoriesLoading &&
                Array.from(Array(10).keys()).map((idx) => (
                  <SwiperSlide
                    key={`story-loader-${idx}`}
                    className="max-w-fit"
                  >
                    <div className="animate-pulse p-4 h-48 group block aspect-square rounded-lg bg-gray-600 dark:bg-gray-800 overflow-hidden"></div>
                    <div className="mt-1 h-6 w-36 rounded-full animate-pulse bg-gray-400 dark:bg-gray-600"></div>
                    <div className="mt-1 h-4 w-24 rounded-full animate-pulse bg-gray-400 dark:bg-gray-600"></div>
                  </SwiperSlide>
                ))}
              {!isStoriesLoading &&
                stories.map((item, idx) => (
                  <SwiperSlide key={`story-${idx}`} className="max-w-fit">
                    <Link to={`/listen/story/${item.id}`}>
                      {item.coverImg ? (
                        <img
                          src={item.coverImg}
                          alt={item.title}
                          className="h-48 aspect-square rounded-lg object-cover"
                        />
                      ) : (
                        <div className="p-4 h-48 flex items-center justify-center group aspect-square rounded-lg bg-gray-600 dark:bg-gray-800 overflow-hidden">
                          <Logo className="h-12 w-12 text-white" />
                        </div>
                      )}{" "}
                      <div className="mt-1 w-48 truncate h5 text-black">
                        {item.title}
                      </div>
                      <div className="mt-1 w-48 truncate text-sm font-bold text-gray-700">
                        @{item.owner.username}
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tours;
