import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { ToastContainer } from "react-toastify";
import useAuth from "../../hooks/useAuth";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import DeleteMultipleStoryModal from "../../partials/stories/DeleteMultipleStoryModal";
import StoryCard from "../../partials/stories/StoryCard";
import PaginationClassic from "../../components/PaginationClassic";
import PaginationPageNumber from "../../components/PaginationPageNumber";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";

function Stories() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [showSelection, setShowSelection] = useState(false);
  const [selection, setSelection] = useState([]);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Add Selection
  const selectHandler = (id, check) => {
    if (check) {
      setSelection([...selection, id]);
    } else {
      setSelection(selection.filter((item) => item !== id));
    }
  };

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  // Get Stories
  const [stories, setStories] = useState(Array(20).fill(""));
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);
  const [isDraft, setIsDraft] = useState(undefined);

  const [count, setCount] = useState(undefined);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  // Delete Story
  const confirmDeleteHandler = async () => {
    try {
      await axiosPrivate.delete(endpoints.STORIES_URL, {
        signal: controller.signal,
        data: { ids: selection },
      });

      // Refresh
      getStories();

      // Clear selection
      setSelection([]);
      setShowSelection(false);

      // Close
      setDeleteModelOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  // Render Stories
  const getStories = async () => {
    try {
      setIsFetching(true);
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        console.log("Search String: " + debouncedSearchString);
        params = { search: debouncedSearchString };
      }

      params = {
        ...params,
        locale: navigator.language,
        offset,
        limit,
        ...(isDraft && { isDraft }),
      };

      const res = await axiosPrivate.get(endpoints.MY_STORIES_ALL, {
        signal: controller.signal,
        params: params,
      });

      const count = parseInt(res.headers["x-total-count"]);
      if (!Number.isNaN(count)) {
        setCount(count);
      }

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setStories(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // Reset Page Number when input
    onChange(1);
    setOffset(0);
  }, [debouncedSearchString]);

  useEffect(() => {
    // Render Stories
    getStories();
  }, [debouncedSearchString, offset, limit, isDraft]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <ToastContainer />

            <DeleteMultipleStoryModal
              open={deleteModelOpen}
              setOpen={setDeleteModelOpen}
              confirmHandler={confirmDeleteHandler}
              selection={selection}
            />

            {/* Page header */}
            <div className="flex justify-between">
              {/* Left */}
              <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-8">
                My Stories ✨
              </h1>
              {/* Right */}
              <div className="flex items-center shadow-sm rounded-md space-x-4">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setIsDraft(true);
                    }}
                    className="-ml-px relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Show Draft
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsDraft(undefined);
                    }}
                    className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Show All
                  </button>
                </div>
                <div>
                  {showSelection ? (
                    <div className="space-x-2">
                      <button
                        type="button"
                        onClick={() => {
                          if (selection.length === stories.length) {
                            setSelection([]);
                          } else {
                            setSelection(stories.map((item) => item.id));
                          }
                        }}
                        className="relative inline-flex items-center px-4 py-2 rounded-md bg-gray-600 hover:bg-gray-700 text-sm font-medium text-white"
                      >
                        Select All
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setSelection([]);
                          setShowSelection(false);
                        }}
                        className="relative inline-flex items-center px-4 py-2 rounded-md bg-gray-600 hover:bg-gray-700 text-sm font-medium text-white"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if (selection.length > 0) {
                            setDeleteModelOpen(true);
                          } else {
                            setShowSelection(false);
                          }
                        }}
                        className="relative inline-flex items-center px-4 py-2 rounded-md bg-rose-600 hover:bg-rose-700 text-sm font-medium text-white"
                      >
                        Confirm Delete
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        setShowSelection(true);
                        setSelection([]);
                      }}
                      className="relative inline-flex items-center px-4 py-2 rounded-md bg-gray-600 hover:bg-gray-700 text-sm font-medium text-white"
                    >
                      Delete Stories
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2">
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
                {/* Filter button */}
                <div>
                  <Link
                    to="/stories/new"
                    className="btn border-slate-200 hover:border-slate-300 bg-white text-emerald-500 hover:bg-emerald-500 hover:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    New Story
                  </Link>
                </div>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between gap-2 gap-x-4">
                <div className="text-slate-600 text-sm flex items-center">
                  <PaginationPageNumber
                    offset={offset}
                    limit={limit}
                    count={count}
                  />
                </div>
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  count={count}
                  result={stories.length}
                />
              </div>
            </div>

            {/* Table */}
            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {stories.map((item, idx) => {
                return (
                  <StoryCard
                    key={`storycard-${idx}`}
                    story={item}
                    fetchHandler={getStories}
                    isLoading={isFetching}
                    editUrl="/stories"
                    showSelection={showSelection}
                    selection={selection}
                    selectHandler={selectHandler}
                  />
                );
              })}
              {!isFetching && stories.length === 0 && (
                <div className="col-span-12 flex items-center justify-center font-bold py-5 text-2xl">
                  {/* Show Empty */}
                  No Found
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Stories;
