import React, { useState, useEffect } from "react";
import moment from "moment";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import QRCode from "react-qr-code";
import axios from "../../api/axios";
import { toast } from "react-toastify";
function GiftcodesTableItem({ coupon, handleClick, isChecked }) {
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const [statistics, setStatistics] = useState([]);
  const [history, setHistory] = useState([]);
  const [histLoading, setHistLoading] = useState(true);
  const [statLoading, setStatLoading] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const getRedemptionStatistics = async () => {
    try {
      setStatLoading(true);
      const res = await axiosPrivate.get(
        endpoints.COUPONS_URL + `/${coupon.id}/stat`,
        {
          signal: controller.signal,
        }
      );

      setStatistics(res.data);
      setStatLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getRedemptionHistory = async () => {
    try {
      setHistLoading(true);

      const res = await axiosPrivate.get(
        endpoints.COUPONS_URL + `/${coupon.id}/history`,
        {
          signal: controller.signal,
        }
      );

      setHistory(res.data);
      setHistLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const openDetailHandler = async () => {
    setDescriptionOpen(!descriptionOpen);
    await Promise.all([getRedemptionHistory(), getRedemptionStatistics()]);
  };

  const getShortURL = async (url) => {
    const res = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAkpOzGvsSIdTavu3dxadWMH-RwEgLElu4`,
      {
        longDynamicLink: url,
        suffix: {
          option: "SHORT",
        },
      }
    );

    return res.data.shortLink;
  };

  const copyLink = async (code) => {
    const longUrl = renderDynamicLink(code);

    // Generate short url from firebase
    const shortUrl = await getShortURL(longUrl);

    // Copy to clipboard
    await navigator.clipboard.writeText(shortUrl);

    // Toast
    toast.success("Copied to clipboard!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // const getShortURL = (url)=>{

  //   const res = await axios.post(,{
  //     "longDynamicLink": "https://link.storiusapp.com/?link=https://link.storiusapp.com/gift-code/FREETOUR&apn=com.storius.storiusapp&ibi=com.storius.storiusapp&isi=1581928786&ofl=https://redeem.storiusapp.com?code%3DFREETOUR&sd=Reedem+Your+Storius+Giftcode",
  //     "suffix": {
  //       "option": "SHORT"
  //     }
  //   }
  //   );
  // }

  const renderDynamicLink = (code) => {
    return `https://link.storiusapp.com/?link=https://link.storiusapp.com/gift-code/${code}&apn=com.storius.storiusapp&ibi=com.storius.storiusapp&isi=1581928786&ofl=https://redeem.storiusapp.com?code%3D${code}&sd=Reedem+Your+Storius+Giftcode`;
  };

  const downloadQR = (id) => {
    const svg = document.getElementById(id);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");

      const downloadLink = document.createElement("a");
      downloadLink.download = `${id}.png`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  return (
    <tbody className="text-sm">
      {/* Row */}
      <tr key={coupon.id}>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={coupon.id}
                className="form-checkbox"
                type="checkbox"
                onChange={handleClick}
                checked={isChecked}
              />
            </label>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center">
            <QRCode
              id={`qrcode-${coupon.code}`}
              size={1024}
              className="hidden"
              value={renderDynamicLink(coupon.code)}
            />
            <button
              type="button"
              onClick={() => {
                downloadQR(`qrcode-${coupon.code}`);
              }}
              className="inline-flex items-center border border-transparent text-xs font-medium rounded text-sky-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => {
                copyLink(coupon.code);
              }}
              className="mr-2 inline-flex items-center border border-transparent text-xs font-medium rounded text-sky-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.7}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </button>
            <div className="font-bold text-emerald-500">{coupon.code}</div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium">
            {!coupon.isUnlimited && `${coupon.redemptionCount}/${coupon.qty}`}
            {coupon.isUnlimited && `${coupon.redemptionCount}/unlimited`}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800">
            {moment(coupon.expiredAt).format("DD-MM-YYYY")}
          </div>
          <div className="text-sm text-emerald-500">
            ({moment(coupon.expiredAt).fromNow("days")})
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left font-medium">{coupon.premiumDays}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div>{moment(coupon.createdAt).format("DD-MM-YYYY")}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              className={`text-slate-400 hover:text-slate-500 transform ${
                descriptionOpen && "rotate-180"
              }`}
              aria-expanded={descriptionOpen}
              onClick={() => openDetailHandler()}
              aria-controls={`description-${coupon.id}`}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
      <tr role="region" className={`${!descriptionOpen && "hidden"}`}>
        <td colSpan="10" className="px-2 first:pl-5 last:pr-5 py-3">
          <div className="flex flex-col space-y-2 bg-slate-50 p-3 -mt-3">
            <div>
              <div className="font-medium text-emerald-500 underline">
                Statistics
              </div>
              <div className="w-full flex justify-center items-center ">
                {/* Statistic */}
                {statLoading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {/* Empty State */}
                {!statLoading && statistics.length === 0 && <div>No Data</div>}
                {/* Data */}
                {!statLoading && statistics.length > 0 && (
                  <ul className="divide-y divide-gray-200 w-full">
                    {statistics.map((row) => {
                      return (
                        <li key={row.date} className="py-4 flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-10 h-10 text-gray-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                            />
                          </svg>

                          <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">
                              {row.count} Redemption
                            </p>
                            <p className="text-sm text-gray-500">
                              Redeemed{" "}
                              {moment(row.date).startOf("day").fromNow()} (
                              {moment(row.date)
                                .startOf("day")
                                .format("DD-MM-YYYY")}
                              )
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            <div>
              <div className="font-medium text-emerald-500 underline">
                Redemption History
              </div>
              <div className="w-full flex justify-center items-center">
                {/* History */}
                {histLoading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {/* Empty State */}
                {!histLoading && history.length === 0 && <div>No Data</div>}
                {/* Data */}
                {!histLoading && history.length > 0 && (
                  <ul className="divide-y divide-gray-200 w-full">
                    {history.map((row) => (
                      <li key={row.user.id} className="py-4 flex">
                        {row.user?.avatar && (
                          <img
                            className="h-10 w-10 rounded-full object-cover"
                            src={row.user.avatar}
                            alt=""
                          />
                        )}

                        {!row.user?.avatar && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-10 h-10 text-gray-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}

                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">
                            {row.user.username}
                          </p>
                          <p className="text-sm text-gray-500">
                            Redeemed{" "}
                            {moment(row.createdAt).startOf("day").fromNow()} (
                            {moment(row.createdAt)
                              .startOf("day")
                              .format("DD-MM-YYYY")}
                            )
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default GiftcodesTableItem;
