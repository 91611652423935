import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import Warning from "../../components/alert/Warning";
import Danger from "../../components/alert/Danger";
import ProcessingBtn from "../../components/btn/ProcessingBtn";
import moment from "moment";

export default function CreateGiftcodeModal({
  isModalOpen,
  setIsModalOpen,
  refresh,
}) {
  function closeModal() {
    setIsModalOpen(false);
  }

  const [warning, setWarning] = useState();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [isLoading, setLoading] = useState(false);

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const res = await axiosPrivate.post(endpoints.COUPONS_URL, values, {
        signal: controller.signal,
      });

      // Refresh
      await refresh();

      // Toast
      toast.success(`Giftcode "${res.data.code}" created successfully`);

      // Close the modal
      setIsModalOpen(!isModalOpen);
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
      } else {
        // API Error
        let error = err?.response?.data?.errors?.msg;

        console.log(err.code);

        if (error === "WRONG_PASSWORD") {
          setWarning(<Warning title="Wrong Password" />);
        } else if (error === "USER_DOES_NOT_EXIST") {
          setWarning(<Warning title="User does not exist" />);
        } else if (error === "BLOCKED_USER") {
          setWarning(<Danger title="User is blocked" />);
        } else if (error === "EMAIL_NOT_VERIFIED") {
          setWarning(<Danger title="Please verify your email address" />);
        } else if (err.code === "ERR_NETWORK") {
          setWarning(<Danger title="No Network" />);
        } else {
          setWarning(<Danger title="Invalid Input" />);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      qty: 0,
      isUnlimited: false,
      premiumDays: null,
      expiredAt: moment().add(7, "days"),
      hasExpiry: false,
    },
    validationSchema: Yup.object({
      code: Yup.string().trim().required("This field is required")
      .matches(/^[^0O]*$/, 'Code cannot contain 0 or O'),
      qty: Yup.number("Must be number")
        .integer("Must be integer")
        .min(0, "Must be greater than 0"),
      isUnlimited: Yup.boolean().required("This field is required"),
      premiumDays: Yup.number("Must be number")
        .integer("Must be integer")
        .min(1, "Must be greater than 0")
        .required("This field is required"),
      expiredAt: Yup.date().optional(),
      hasExpiry: Yup.boolean().required("This field is required"),
    }),
    onSubmit: submitHandler,
  });

  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Create Giftcode
                  </Dialog.Title>
                  {/* Warning */}
                  {warning !== null && <div className="py-2">{warning}</div>}

                  {/* Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="space-y-4">
                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="code"
                        >
                          Code
                        </label>
                        <input
                          name="code"
                          className="form-input w-full text-lg sm:text-base"
                          type="string"
                          value={formik.values.code}
                          onInput={(e) => {
                            e.target.value = e.target.value.toUpperCase();
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.code && formik.touched.code
                            ? `Error: ${formik.errors.code}`
                            : null}
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center space-x-4">
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="code"
                          >
                            Has Unlimited Quantity
                          </label>
                          <div className="form-switch">
                            <input
                              name="isUnlimited"
                              type="checkbox"
                              id="isUnlimited"
                              className="sr-only"
                              checked={formik.values.isUnlimited}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="bg-slate-400"
                              htmlFor="isUnlimited"
                            >
                              <span
                                className="bg-white shadow-sm"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">
                                Unlimited Quantity
                              </span>
                            </label>
                          </div>
                        </div>
                        <p className="text-red-600 text-sm">
                          {formik.errors.isUnlimited &&
                          formik.touched.isUnlimited
                            ? `Error: ${formik.errors.isUnlimited}`
                            : null}
                        </p>
                      </div>
                      {!formik.values.isUnlimited && (
                        <div>
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="qty"
                          >
                            Quantity
                          </label>
                          <input
                            name="qty"
                            className="form-input w-full text-lg sm:text-base"
                            type="number"
                            value={formik.values.qty}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <p className="text-red-600 text-sm">
                            {formik.errors.qty && formik.touched.qty
                              ? `Error: ${formik.errors.qty}`
                              : null}
                          </p>
                        </div>
                      )}
                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="premiumDays"
                        >
                          Premium Days
                        </label>
                        <input
                          name="premiumDays"
                          className="form-input w-full text-lg sm:text-base"
                          type="string"
                          value={formik.values.premiumDays}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.premiumDays &&
                          formik.touched.premiumDays
                            ? `Error: ${formik.errors.premiumDays}`
                            : null}
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center space-x-4">
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="hasExpiry"
                          >
                            Will Expired
                          </label>
                          <div className="form-switch">
                            <input
                              name="hasExpiry"
                              type="checkbox"
                              id="hasExpiry"
                              className="sr-only"
                              checked={formik.values.hasExpiry}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label className="bg-slate-400" htmlFor="hasExpiry">
                              <span
                                className="bg-white shadow-sm"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Will Expired</span>
                            </label>
                          </div>
                        </div>
                        <p className="text-red-600 text-sm">
                          {formik.errors.hasExpiry && formik.touched.hasExpiry
                            ? `Error: ${formik.errors.hasExpiry}`
                            : null}
                        </p>
                      </div>
                      {formik.values.hasExpiry && (
                        <div>
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="expiredAt"
                          >
                            Expiry Day
                          </label>
                          <input
                            name="expiredAt"
                            className="form-input w-full text-lg sm:text-base"
                            type="date"
                            value={formik.values.expiredAt}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <p className="text-red-600 text-sm">
                            {formik.errors.expiredAt && formik.touched.expiredAt
                              ? `Error: ${formik.errors.expiredAt}`
                              : null}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="flex items-center justify-between mt-6 space-x-4">
                      <button
                        type="button"
                        onClick={() => setIsModalOpen(!isModalOpen)}
                        className="btn bg-slate-500 hover:bg-slate-600 text-white w-full"
                      >
                        Close
                      </button>
                      {!isLoading && (
                        <button
                          type="submit"
                          className="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full"
                        >
                          Create Giftcode
                        </button>
                      )}
                      {isLoading && <ProcessingBtn title="Create Giftcode" />}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
