import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditMenu from "../../components/DropdownEditMenu";
import moment from "moment";
import { RWebShare } from "react-web-share";

import DeleteModal from "./DeleteModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { toast } from "react-toastify";
import { ReactComponent as Logo } from "../../images/logo/LogoWhite.svg";
import FRONTEND_ENDPOINT from "../../utils/frontend";

function StoryCard(props) {
  // Delete Story
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  let share_link = FRONTEND_ENDPOINT || "https://web.storiusapp.com";

  // Remove Story Handler
  const removeStoryHandler = async (storyId) => {
    try {
      setIsLoading(true);
      await axiosPrivate.delete(endpoints.STORIES_URL + "/" + storyId, {
        signal: controller.signal,
      });

      props.fetchHandler();

      // Toast
      toast.success("Story has been removed successfully", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPublishStatus = (status) => {
    switch (status) {
      case "pending":
        return (
          <span className="absolute top-0 w-full flex justify-center items-center px-2.5 py-0.5 text-xs font-bold bg-gray-500 text-white rounded-t-md">
            Pending to publish
          </span>
        );
      case "approved":
        return (
          <span className="absolute top-0 w-full flex justify-center items-center px-2.5 py-0.5 text-xs font-bold bg-emerald-500 text-white rounded-t-md">
            Published
          </span>
        );
      case "rejected":
        return (
          <span className="absolute top-0 w-full flex justify-center items-center px-2.5 py-0.5 text-xs font-bold bg-rose-500 text-white rounded-t-md">
            Rejected to publish
          </span>
        );
      default:
        return null;
    }
  };

  if (props.isLoading) {
    return (
      <div className="animate-pulse col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-lg border border-slate-200">
        <div className="flex flex-col h-full">
          {/* Card top */}
          <div className="grow">
            <div className="relative flex justify-between items-start">
              {/* Image + name */}
              <header className="w-full">
                <div className="flex flex-col mb-2">
                  <div className="relative w-full h-48">
                    <div className="h-full w-full bg-slate-200 rounded-t-lg" />
                  </div>
                </div>

                <div className="mt-1 p-5">
                  <div className="flex flex-col items-start gap-y-2">
                    <div className="h-5 bg-slate-200 w-1/2 rounded-full"></div>
                    <div className="h-5 bg-slate-200 w-full rounded-full"></div>
                  </div>
                </div>
              </header>
            </div>
          </div>
          {/* Card footer */}
          <div className="border-t border-slate-200">
            <div className="flex divide-x divide-slate-200r">
              <div className="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4">
                <div className="flex items-center justify-center">
                  <div className="h-5 w-full bg-emerald-200 rounded-full"></div>
                </div>
              </div>
              <div className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 font-medium px-3 py-4 group">
                <div className="flex items-center justify-center">
                  <div className="h-5 w-full bg-slate-200 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Empty Trash Modal */}
      <DeleteModal
        id={props.story.id}
        deleteFunction={removeStoryHandler}
        open={dangerModalOpen}
        setOpen={setDangerModalOpen}
        isLoading={isLoading}
      />

      {/* Rest */}
      <div className="col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-lg border border-slate-200">
        <div className="flex flex-col h-full">
          {/* Card top */}
          <div className="grow">
            <div className="relative flex justify-between items-start">
              {/* Image + name */}
              <header className="w-full">
                <div className="flex flex-col">
                  <div className="relative flex items-center justify-center w-full h-48 bg-gray-600 rounded-t-md">
                    {props.story.isDraft && (
                      <span className="absolute top-0 w-full flex justify-center items-center px-2.5 py-0.5 text-xs font-bold bg-black text-white rounded-t-md">
                        Draft
                      </span>
                    )}
                    {renderPublishStatus(props.story.publishStatus)}

                    {props.story.coverImg ? (
                      <img
                        className="h-full w-full object-cover overflow-hidden rounded-t-md"
                        src={props.story.coverImg}
                        alt={props.story.title}
                      />
                    ) : (
                      <Logo className="h-8 w-8 " />
                    )}

                    {/* View, Like, Bookmarked Badge */}
                    {props.story.title && (
                      <div className="absolute bottom-0 left-0 text-sm inline-flex font-bold bg-slate-800 text-slate-100 rounded-r-md text-start px-2.5 py-1">
                        {props.story.title}
                      </div>
                    )}
                    {/* Premium Badges*/}
                    {props.story.isPremium && (
                      <div className="absolute top-5 left-5 text-sm inline-flex font-bold bg-gradient-to-r from-main to-secondary text-slate-100 rounded-md text-center px-2.5 py-1">
                        Premium
                      </div>
                    )}
                  </div>

                  <div className="p-5">
                    <div className="flex justify-between items-start gap-x-2">
                      <div className="text-sm text-gray-500 inline-flex items-center gap-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                          />
                        </svg>
                        {props.story.owner?.username ?? "[NOT FOUND]"}
                      </div>
                      {props.story.duration !== null && (
                        <div className="text-sm text-gray-500 flex items-center gap-x-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z"
                            />
                          </svg>
                          {moment
                            .utc(parseInt(props.story.duration) * 1000)
                            .format("mm:ss")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </header>
              {/* Menu button */}
              {props?.showSelection ? (
                <div className="absolute top-0 right-0 p-5 inline-flex shrink-0 drop-shadow-md border-gray-500">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    checked={props.selection.includes(props.story.id)}
                    onChange={(e) => {
                      props.selectHandler(props.story.id, e.target.checked);
                    }}
                    className="w-8 h-8 text-rose-500 border-gray-300 rounded-full"
                  />
                </div>
              ) : (
                <EditMenu
                  align="right"
                  className="absolute top-0 right-0 p-5 inline-flex shrink-0 drop-shadow-md border-gray-500"
                >
                  <li>
                    <RWebShare
                      data={{
                        text: "Listen to this amazing story!",
                        url:
                          share_link +
                          "/listen/story/" +
                          props.story.id +
                          "?locale=" +
                          props.story.locale,
                        title: props.story.title,
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <Link className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3">
                        Share
                      </Link>
                    </RWebShare>
                  </li>
                  <li>
                    {
                      //if user is the story owner/admin, it should display this button
                    }
                    {(props.user?.role === "admin" ||
                      props.story.owner?.username === props.user?.username) && (
                      <button
                        className="w-full font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDangerModalOpen(true);
                        }}
                      >
                        Delete
                      </button>
                    )}
                  </li>
                </EditMenu>
              )}
            </div>
          </div>
          {/* Card footer */}
          <div className="border-t border-slate-200">
            <div className="flex divide-x divide-slate-200r">
              <a
                className="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4"
                href={`${FRONTEND_ENDPOINT}/listen/story/${props.story.id}?locale=${props.story.locale}`}
              >
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 shrink-0 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                    />
                  </svg>

                  <span>Listen Story</span>
                </div>
              </a>

                <Link
                  className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 font-medium px-3 py-4 group"
                  to={`${props.editUrl}/${props.story.id}/edit`}
                >
                  <div className="flex items-center justify-center">
                    <svg
                      className="w-4 h-4 fill-current text-slate-400 group-hover:text-slate-500 shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                    </svg>
                    <span>Edit</span>
                  </div>
                </Link>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StoryCard;
