import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import LoadingBtn from "../../components/LoadingBtn";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Loader from "../../partials/Loader";
import LanguageSelectBar from "../../partials/stories/LanguageSelectBar";

import GlobalSettingComponent from "../../partials/stories/GlobalSettingComponent";
import RenderLanguageForm from "../../partials/stories/RenderLanguageForm";

function EditStory(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const navigate = useNavigate();

  const { auth } = useAuth();
  const user = auth.user;
  const [story, setStory] = useState({
    languages: [],
    location: { coordinates: [] },
    owner: "",
    visibility: 0,
    themes: [],
    // tags: [],
    isEnabled: true,
    isDraft: false,
    isPremium: false,
    isExclusive: false,
    priority: 1,
  });
  const [languages, setLanguages] = useState({});
  const [marker, setMarker] = useState([]);
  const [storyOwner, setOwner] = useState(user);

  // Params
  const { id } = useParams();

  const getStory = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.STORIES_URL + `/${id}`, {
        signal: controller.signal,
      });

      if (res.data.languages.length > 0) {
        setSelectedLocale(res.data.languages[0].locale);
      }
      setStory(res.data);
      setMarker(res.data?.location?.coordinates || []);
      setOwner(res.data.owner);
    } catch (error) {
      console.error(error);
    }
  };

  const getLanguages = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.LANGS_URL, {
        signal: controller.signal,
      });

      setLanguages(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const main = async () => {
    try {
      await Promise.all([getStory(), getLanguages()]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    main();
  }, []);

  const saveAsDraft = async () => {
    try {
      await axiosPrivate.patch(
        endpoints.STORIES_URL + `/${formik.values.id}`,
        { ...formik.values, isDraft: true },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      toast.success("Save as draft Successfully!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // To to previous page
      navigate(props.fallback || "/stories");
    } catch (err) {
      let error = err?.response.data.errors.msg;
      toast.error(error, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setIsSaving(true);
    try {
      if (values.languages.length === 0) {
        toast.error("Please add at least one language", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Check if file is uploaded
        const isFileUploaded = values.languages.every((item) => {
          let isUploaded =
            item.coverImg !== "loader" && item.audioUrl !== "loader";

          // Check media
          if (item.medias.length > 0) {
            isUploaded = item.medias.every((media) => {
              return media !== "loader";
            });
          }

          return isUploaded;
        });

        if (!isFileUploaded) {
          toast.success("Please wait for file to be uploaded!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          await axiosPrivate.patch(
            endpoints.STORIES_URL + `/${formik.values.id}`,
            formik.values,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          );

          // console.log(values);

          await getStory();

          toast.success("Edit Successfully!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        console.error("No Server res");

        toast.error("No Server res", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        let error = err?.response.data.errors.msg;
        toast.error(error, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: story.id,
      languages: story.languages.map((item) => {
        return {
          title: item?.title || "",
          address: item?.address || "",
          description: item?.description || "",
          transcript: item?.transcript || "",
          coverImg: item?.coverImg || "",
          youtubeId: item?.youtubeId || "",
          audioUrl: item?.audioUrl || "",
          medias: item?.medias || [],
          locale: item?.locale || "",
        };
      }),
      location: story?.location?.coordinates || [],
      owner: story.owner.id,
      visibility: story?.visibility,
      themes: story?.themes || [],
      // tags: story.tags,
      isEnabled: story.isEnabled,
      isDraft: story.isDraft,
      isPremium: story.isPremium,
      isExclusive: story.isExclusive,
      priority: story?.priority,
    },
    validationSchema: Yup.object({
      languages: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("This field is required"),
          address: Yup.string().required("This field is required"),
          description: Yup.string().optional(),
          transcript: Yup.string().optional(),
          coverImg: Yup.string().required("This field is required"),
          medias: Yup.array().of(Yup.string()).optional(),
          locale: Yup.string().required("This field is required"),
        })
      ),
      owner: Yup.string().required("This field is required"),
      location: Yup.array()
        .of(Yup.number())
        .required("This field is required")
        .min(2, "This field is required"),
      visibility: Yup.number()
        .integer()
        .min(0)
        .max(2)
        .required("This field is required"),
      themes: Yup.array().of(Yup.string()).optional(),
      // tags: Yup.array().of(Yup.string()).optional(),
      isEnabled: Yup.boolean().required("This field is required"),
      isDraft: Yup.boolean().required("This field is required"),
      isPremium: Yup.boolean().required("This field is required"),
      isExclusive: Yup.boolean().required("This field is required"),
      priority: Yup.number()
      .integer()
      .min(0)
      .max(100)
      .required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const setStoryOwner = (user) => {
    // Display
    setOwner(user);

    // Formik
    formik.setFieldValue("owner", user.id);
  };

  const updateLocation = (lnglat) => {
    // Update Formik
    formik.setFieldValue("location", lnglat);

    // Update Marker
    setMarker(lnglat);
  };

  const handleMapClick = (event) => {
    const lnglat = [event.lngLat.lng, event.lngLat.lat];
    updateLocation(lnglat);
  };

  if (isLoading) return <Loader />;
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <ToastContainer />

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="mx-auto flex flex-col space-y-8 rounded-lg bg-gray-50 p-5">
                {/* Language */}
                <div className="w-full space-y-4">
                  {/* Page content */}
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Edit Story
                  </h3>
                  <div className="space-y-6 sm:space-y-5">
                    <LanguageSelectBar
                      languages={languages}
                      selectedLocale={selectedLocale}
                      setSelectedLocale={setSelectedLocale}
                      formik={formik}
                    />
                    {formik.values.languages &&
                      formik.values.languages.length > 0 &&
                      formik.values.languages.map((item, idx) => {
                        if (item.locale !== selectedLocale) return;
                        return <RenderLanguageForm formik={formik} idx={idx} />;
                      })}
                  </div>
                </div>
              </div>

              <GlobalSettingComponent
                marker={marker}
                setMarker={setMarker}
                formik={formik}
                story={story}
                user={user}
                storyOwner={storyOwner}
                setStoryOwner={setStoryOwner}
              />

              <div className="py-5">
                <div className="flex justify-end items-center space-x-4">
                  <Link
                    to={props.fallback || "/stories"}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  >
                    Go Back
                  </Link>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      saveAsDraft();
                    }}
                    className="btn bg-gray-500 hover:bg-gray-600 text-white"
                  >
                    Save as Draft
                  </button>
                  <div>
                    {isSaving ? (
                      <LoadingBtn text="Saving" />
                    ) : Object.keys(formik.errors).length > 0 ? (
                      <button
                        type="button"
                        disabled
                        className="btn bg-emerald-400 text-white cursor-not-allowed"
                      >
                        Save Changes
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {/* Content */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditStory;
